@import "../../node_modules/bootstrap/scss/functions";

/* ********************************* Variables overrides  ********************************* */

// Main
$primary: #314175;
$secondary: #cf3f7c;
$grayLight: #D7D8D9;
$gray: #F0F0F2;
$grayLighter: #EAEAEA;
$grayText: #76777B;
$green: #00C283;
$red: #DC3545;
$yellowDark: #F18C18;
$tags: #49A4DA;
$link: #0071CE;

// Border
$border-color: $grayLight ;

// Fonts
$font-weight-bold: 500;
$font-size-root: 14px;

// inputs
$component-active-bg: $grayLight;
$input-border-radius: 0 !default;
$input-border-color: $grayLight !default;
$input-btn-focus-color: $grayLight;

// dropdown
$btn-border-radius: 0 !default;
$dropdown-border-radius: 0;

$position-values: (
  0: 0,
  5: 5px,
  10: 10px,
  15: 15px,
  20: 20px,
  50: 50%,
  100: 100%
) !default;

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 1.8,
  6: $spacer * 3,
) !default;

// Font family
@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/HelveticaNeueRoman.otf");
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/HelveticaNeueLight.otf");
  font-weight: 300;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/HelveticaNeueMedium.otf");
  font-weight: 500;
}
@font-face {
  font-family: "Helvetica Neue";
  src: url("../assets/fonts/HelveticaNeueBold.otf");
  font-weight: 600;
}
$font-family-sans-serif: "Helvetica Neue";

@import "../../node_modules/bootstrap/scss/variables";

/* ********************************* *** ********************************* */

$custom-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "gray": $gray,
  "gray-light": $grayLight,
  "gray-lighter": $grayLighter,
  "gray-text": $grayText,
  "green": $green,
  "red": $red,
  "yellow-dark": $yellowDark,
  "tags": $tags,
  "link": $link
);

$utilities-text-colors: map-merge($utilities-text-colors, $custom-colors);
$theme-colors: map-merge($theme-colors, $custom-colors);
$utilities-bg-colors: map-merge($utilities-bg-colors, $custom-colors);

/* ********************************* Utilities ********************************* */

@import "../../node_modules/bootstrap/scss/utilities";

$utilities: map-merge($utilities, (
  "z-index": (
    property: z-index,
    class: zi,
    values: (
      1: 100,
      2: 1000,
      3: 10000,
      4: 100000
    )
  ),
  "border-color": (
    property: border-color,
    class: border,
    values: map-merge($theme-colors, ("white": $white))
  ),
  "text": (
    property: color,
    class: text,
    state: hover,
    values: $utilities-text-colors
  ),
  "width": (
    property: width,
    class: w,
    values: (
      25: 25%,
      40: 40%,
      50: 50%,
      75: 75%,
      100: 100%,
      auto: auto
    )
  ),
  "max-width": (
    property: max-width,
    class: mw,
    values: (
      calendar: calc(100%/7),
      fc: fit-content,
      200px: 200px,
      225px: 225px,
      250px: 250px,
      450px: 450px,
      675px: 675px,
      50: 50%,
      100: 100%
    )
  ),
  "flex": (
    property: flex,
    class: col,
    values: (
      calendar: 0 1 calc(100%/7),
      '200px': 200px 0
    )
  ),
  "font-size": (
    property: font-size,
    class: fs,
    values: map-merge($font-sizes, (
      8: 8px,
      10: 10px,
      12: 12px,
      16: 16px,
      20: 20px,
      24: 24px,
      40: 40px,
    ))
  ),
  "shadow": (
    property: box-shadow,
    class: shadow,
    values: (
      null: $box-shadow,
      sm: $box-shadow-sm,
      lg: $box-shadow-lg,
      none: none,
      box: 0px 1px 2px rgba(0, 0, 0, 0.25),
    )
  ),
  "opacity": (
    property: opacity,
    class: opacity,
    values: (
      0: 0,
      15: .15,
      25: .25,
      50: .5,
      75: .75,
      100: 1,
    )
  )
));

@import "../../node_modules/bootstrap/scss/bootstrap.scss";

/* ********************************* Custom styles ********************************* */

.clickable {
  cursor: pointer;
}

.hoverable {
  &:hover {
    background-color: $gray;
  }
}

.dropdown-caret::after {
  position: absolute;
  right: 10px;
  top: 50%;
}

.link {
  text-decoration: none;
  color: $secondary;

  &:hover {
    color: $secondary;
    text-decoration: underline !important;
  }
}

.underlined {
  border-bottom: solid $secondary 4px;
}

.active-topbar {
  border-bottom: solid $secondary 8px;
}

.main-container {
  min-height: calc(100vh - 65px);
}

.home-clients-container {
  max-height: calc(100vh - 60px - 170px);
  overflow-x: hidden;
  overflow-y: scroll;
  border-left: 1px solid $grayLight;
  border-bottom: 1px solid $grayLight;
  
  &-table{
    max-height: calc(100vh - 60px - 170px - 70px);
  }
}

.home-client-detail-items-container {
  // max-height: calc(100vh - 65px - 50px - 280px - 150px);
  // max-height: calc(100vh - 65px);
  // overflow-x: hidden;
  // overflow-y: scroll;
  // overflow: auto;
  border-right: 1px solid $grayLight;
  border-left: 1px solid $grayLight;
  border-bottom: 1px solid $grayLight;
}

.home-similar-items-container {
  max-height: calc(100vh - 60px - 240px - 47px);
  overflow-x: hidden;
  overflow-y: scroll;
  border-left: 1px solid $grayLight;
  border-bottom: 1px solid $grayLight;
}

*::-webkit-scrollbar {
  width: 12px;
  background-color: transparent;
}

*::-webkit-scrollbar-track {
  border: 1px solid $grayLight;
  background-color: white;
}

*::-webkit-scrollbar-thumb {
  background-color: #C4C4C4
}

.mw {
  &-fc {
    max-width: fit-content;
  }
}

.tooltip > div.tooltip-inner {
  background-color: #636363;
  color: white;
  font-size: 12px;
  padding: 5px;
  opacity: 0.8;
  border-radius: 6px;
  max-width: none;
  white-space: nowrap;
}

.tooltip.show {
  opacity: 1;
}

.tooltip > div.arrow::before {
  border-bottom-color: #636363;
  color: white;
}

@import "./DatePicker.scss";
@import "./Spinner.scss";