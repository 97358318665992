.react-datepicker {
  border: 1px solid var(--bs-gray-light);
  font-size: var(--bs-root-font-size);
  box-shadow: 0 2px 5px 2px var(--bs-gray-light);

  &__triangle::after {
    border-bottom-color: var(--bs-primary) !important;
  }

  &__header {
    border: none !important;
    background-color: var(--bs-primary) !important;
  }

  &__day--in-selecting-range {
    background-color: var(--bs-secondary) !important;
    opacity: 0.5;
  }

  &__day--selected,
  &__day--keyboard-selected,
  &__day--in-range {
    background-color: var(--bs-secondary) !important;
    color: var(--bs-white) !important;
    opacity: 1;
  }

  &__current-month,
  &-time__header,
  &__day-name,
  &__week,
  &__day--selected {
    color: var(--bs-white) !important;
  }

  &__navigation-icon {
    font-size: var(--bs-root-font-size);
  }
}

.react-datepicker-wrapper {

  input {
    border: 1px solid var(--bs-gray-light);
    // text-align: center !important;
    transition: box-shadow 0.15s ease-in-out;

    &:focus {
      outline: none;
      // box-shadow: 0 0 0 0.25rem #d7d8d9;
    }
  }
}

.react-datepicker-popper {
  z-index: 1030 !important;
}

.home-daterange-picker {
  .react-datepicker-wrapper {
    input {
      width: 90px !important;
      height: 31.5px !important;
      border: none !important;
    }
  }

  .react-datepicker-wrapper:last-child input {
    text-align: right !important;
  }
}